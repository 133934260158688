:root {
  --primary-accent-color: #369ef3;
  --secondary-accent-color: #7ab20a;

  --primary-text-color: #333333;
  --text-black: #000000;
  --text-black-opacity-6: rgba(0, 0, 0, 0.6);
  --text-black-opacity-8: rgba(0, 0, 0, 0.87);

  --offer-first-title-background: linear-gradient(to bottom, #eeeeee, #ffffff);

  --image-bakcground-color: #f8f8f8;

  --include-background-color: #f6f4f3;
  --include-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --button-border-color: rgb(106, 146, 20);
}
