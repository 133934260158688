.container {
  text-align: center;
  font-size: 14.3px;
}

.title {
  font-size: 20.4px;
  text-align: center;
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: 0.25px;
  color: var(--text-black-opacity-8);

  .titleTimer {
    color: var(--primary-accent-color);
  }
}

.price {
  text-align: center;
  margin: 12px auto auto auto;
  line-height: 1.4;
  letter-spacing: 0.25px;
  color: var(--text-black-opacity-6);
  max-width: 288px;
  font-weight: 400;
  white-space: pre-wrap;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .timer {
    color: var(--primary-accent-color);
    margin-top: 16px;
    font-weight: 500;
    line-height: 1.68;
    letter-spacing: 0.1px;
  }

  .specialistBlock {
    margin-top: 28px;
    display: flex;
    justify-content: center;
    align-content: center;

    .specialist {
      min-height: 128px;
      position: relative;
      min-width: 128px;
      border-radius: 50%;
      background-color: var(--premium-upsell-include-image-background);

      @media (max-width: 458px) {
        min-height: 124px;
      }
    }

    .recommendation {
      margin-left: 20px;
      font-size: 14.3px;
      font-weight: 500;
      line-height: 1.68;
      letter-spacing: 0.1;
      color: var(--text-black-opacity-6);
      text-align: left;
      align-self: center;
    }
  }

  .mainTitleWrapper {
    margin-top: -12px;
  }

  .mainTitleWrapper::before {
    content: '';
    width: 0;
    height: 0;
    left: -115px;
    top: 10px;
    position: relative;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 16px solid #eeeeee;
  }

  .mainTitle {
    padding: 27px 16px 16px 16px;
    background: var(--offer-first-title-background);
  }
}

.testimonial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  text-align: left;
  align-items: center;

  .testimonialTitle {
    font-weight: 500;
    font-size: 20.4px;
    letter-spacing: 0.25px;
  }

  .testimonialSubTitle {
    margin: 13px 0 8px;
    font-size: 20.4px;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: normal;
    color: var(--text-black-opacity-8);
  }

  .testimonialText {
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    font-weight: 400;
  }

  .jump {
    position: relative;
    margin: 28px auto auto auto;
    background-color: var(--image-bakcground-color);
  }

  .jumpfemale {
    min-width: 268px;
    min-height: 292px;
  }

  .jumpmale {
    min-height: 249px;
    min-width: 287px;
  }

  .testimonialImageWrapper {
    margin-top: 28px;
    min-height: 349px;
    margin-bottom: 10px;
    position: relative;
    border-radius: 3px;
    width: 100%;
    background-color: var(--image-bakcground-color);

    .testimonialImage {
      border-radius: 3px;
    }
  }

  img {
    @media (max-width: 458px) {
      object-fit: cover;
      min-height: 280px;
    }
  }
}

.includes {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  align-items: center;

  .includeList {
    margin: 28px 0;
  }

  .includeItem {
    display: flex;
    text-align: left;
    margin-top: 12px;
    border-radius: 4px;
    box-shadow: var(--include-box-shadow);
    background-color: var(--include-background-color);

    .includeLeft {
      position: relative;
      flex: 1 1;
      width: 100%;
      background-color: var(--image-bakcground-color);

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      svg {
        position: absolute;
        z-index: 1;
        height: 30px;
        width: 30px;
        margin-top: 10px;
        margin-left: 10px;

        @media (max-width: 458px) {
          margin: 8px 0 0 8px;
          height: 24px;
          width: 24px;
        }
      }
    }

    .includeRight {
      flex: 2 1;
      background-color: var(--premium-upsell-include-text-background);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;

      .includeTitle {
        line-height: normal;
        margin: 16px 16px 0 16px;
        font-size: 20.4px;
        font-weight: 500;
        letter-spacing: 0.25px;
        color: var(--text-black-opacity-8);
      }

      .includeParagraph {
        margin: 0 16px 16px 16px;
        font-size: 14.3px;
        line-height: 1.4;
        letter-spacing: 0.25px;
        color: var(--text-black);
      }
    }
  }

  .includeItem:first-child {
    margin-top: 0;
  }
}

.outlinedButton {
  margin: 28px auto 100px auto;
  width: 288px;
  min-height: 48px;
  padding: 8px 16px;
  border: solid 1px var(--secondary-accent-color);
  border-radius: 3px;
  cursor: pointer;
  text-transform: uppercase;
  background-color: rgba(98, 2, 238, 0);
  font-size: 14.3px;
  font-weight: 500;
  line-height: 1.12;
  letter-spacing: 1.25px;
  color: var(--secondary-accent-color);

  @media (max-width: 458px) {
    margin-bottom: 40px;
  }
}

.outlineSmall {
  min-height: 32px;
  margin-top: 16px;
}

.outlinedButton:hover,
.outlinedButton:active,
.outlinedButton:focus {
  border: 1px solid var(--button-border-color);
}

.outlinedButton:active {
  background-color: rgb(106, 146, 20, 0.1);
}

.subPrice {
  text-align: center;
  margin: 12px auto auto auto;
  line-height: 1.4;
  letter-spacing: 0.25px;
  color: var(--text-black-opacity-6);
  max-width: 288px;
  font-weight: 400;
  margin-top: 0px;
}

.includes + .testimonial > .testimonialBlock > .testimonialImageWrapper {
  margin-top: 0;
}

.testimonialBlock + .title {
  margin-top: 40px;
}
