body {
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--primary-text-color);

  button {
    text-decoration: none;
    outline: none;
  }
}

.wrapper {
  max-width: 358px;
  margin: 0 auto;
  padding-top: calc(env(safe-area-inset-top) + 20px);
  padding-bottom: calc(env(safe-area-inset-top) + 20px);

  @media (max-width: 458px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.button {
  width: 288px;
  min-height: 48px;
  padding: 8px 16px;
  border: solid 1px #91c81b;
  background-image: linear-gradient(to bottom, #a6de01, #7ab20a);
  text-shadow: 0 1px 0 #608512;
  font-size: 14.3px;
  font-weight: bold;
  line-height: 1.12;
  letter-spacing: 1.25px;
  text-align: center;
  color: rgba(255, 255, 255, 0.87);
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
}

.button:hover,
.button:active,
.button:focus {
  border: 1px solid var(--button-border-color);
}

.button:active {
  background-color: #80ab01;
  background-image: none;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}

.button:disabled {
  border: none;
  cursor: not-allowed;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}

.small {
  min-height: 32px;
}

.download {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.loader-s {
  display: inline-block;
  margin: 0 auto;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid var(--primary-accent-color);
  width: 10px;
  height: 10px;
  animation: spin 1.5s linear infinite;
}

.loader-md {
  display: inline-block;
  margin: 0 auto;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid var(--primary-accent-color);
  width: 16px;
  height: 16px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.errorMessage {
  right: 25px;
  position: absolute;
  padding: 15px 10px;
  color: #fff;
  background-color: #b00020;
  border-radius: 3px;
  font-size: 14.3px;

  @media (max-width: 458px) {
    margin-top: 20px;
    width: 80%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.minor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg + span {
    margin-top: 28px;
  }

  span + p {
    margin-top: 8px;
    margin-bottom: 0;
  }

  p + button,
  a {
    margin-top: 28px;
  }

  button + button {
    margin-bottom: 0;
  }

  button + div {
    max-width: 288px;
    margin-top: 12px;
    font-size: 14.3px;
    color: var(--text-black-opacity-6);
    letter-spacing: 0.25px;
  }

  .mobile {
    color: #337ab7;
    text-decoration: none;
  }
}
